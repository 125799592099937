/**
 * import and init third party
 * components/validators and other dependencies
 */

import Vue from 'vue'
import * as THREE from 'three'
import {
  TweenLite
} from 'gsap'
import {
  ReportingService
} from '@/services/reporting.service'
import {
  Camera_Positions,
  Scene_Names
} from '@/.env'
THREE.OrbitControls = require('three-orbit-controls')(THREE)

export class Scene {
  camera
  scenes = []
  layer
  renderer
  controls = null
  isUserInteracting = false
  tooltipActive = false
  lon = 0
  lat = 0
  phi = 0
  theta = 0
  onPointerDownPointerX = 0
  onPointerDownPointerY = 0
  onPointerDownLon = 0
  onPointerDownLat = 0
  points = []
  emitSpriteHoverOut = true
  emitSpriteHoverIn = false
  langue
  position
  questionIndex

  constructor(ele, rdcWelcome, rdcHall, rdcOpenSpace, rdcOpenSpaceHall, rdcOutSide, rdcMeetingRoom,
    firstWelcome, firstOffice1, firstOpenSpace1, firstOpenSpace2, firstOffice2, secondWelcome,
    secondOpenSPace, secondMeetingRoom, thirdOutSide) {
    this.container = ele
    this.rdcWelcome = rdcWelcome
    this.rdcHall = rdcHall
    this.rdcOpenSpace = rdcOpenSpace
    this.rdcOpenSpaceHall = rdcOpenSpaceHall
    this.rdcOutSide = rdcOutSide
    this.rdcMeetingRoom = rdcMeetingRoom
    this.firstWelcome = firstWelcome
    this.firstOffice1 = firstOffice1
    this.firstOpenSpace1 = firstOpenSpace1
    this.firstOpenSpace2 = firstOpenSpace2
    this.firstOffice2 = firstOffice2
    this.secondWelcome = secondWelcome
    this.secondOpenSPace = secondOpenSPace
    this.secondMeetingRoom = secondMeetingRoom
    this.thirdOutSide = thirdOutSide
    this.raycaster = new THREE.Raycaster()
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  // Creating Sphere
  createSphere(
    textureMaterial = 'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/2294472375_24a3b8ef46_o.jpg'
  ) {
    const name = 'environmentSphere'
    const geometry = new THREE.SphereGeometry(100, 55, 55)
    const texture = new THREE.TextureLoader().load(textureMaterial)
    texture.depthWrite = false
    texture.wrapS = THREE.RepeatWrapping
    texture.repeat.x = -1
    texture.mapping = THREE.EquirectangularReflectionMapping
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide
    })
    material.transparent = true
    const mesh = new THREE.Mesh(geometry, material)
    mesh.name = name
    return mesh
  }

  sendStats(typeS, detailS) {
    const data = {
      type: typeS,
      detail: detailS,
      version: localStorage.getItem('version'),
      SessionId: localStorage.getItem('userID')
    }
    ReportingService.sendReport(data)
    // console.log(res)
  }

  // making video play
  playVideo() {
    const video = document.getElementById('video')
    video.play()
  }

  // Creating sprite
  addSprite(
    point = new THREE.Vector3(14, 1.9, -47),
    name = 'nextScene',
    popoverContent = 'content',
    texture = '/Mazars/info.png'
  ) {
    const map = new THREE.TextureLoader().load(texture)
    const spriteMaterial = new THREE.SpriteMaterial({
      map: map,
      alphaTest: 0.5
    })
    const sprite = new THREE.Sprite(spriteMaterial)
    sprite.name = name
    sprite.scale.multiplyScalar(2.5)
    sprite.popoverContent = popoverContent
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(40)
    )
    return sprite
  }

  // Function to move to another scene
  goToScene(sceneIndex) {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const position = Camera_Positions[sceneIndex]
    var Scene_Name = Scene_Names[sceneIndex].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites(sceneIndex)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  changeToScene1() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene1 = new THREE.Scene()
    scene1.name = 'rdc Hall'
    scene1.add(this.createSphere(this.rdcHall))
    scene1.add(this.addSprite(new THREE.Vector3(-0.7, -1.2, 0.7), 'changeToScene0', 'rdc Welcome', ''))
    scene1.add(this.addSprite(new THREE.Vector3(12, 0.4, 1.5), 'clickedToPlay2', 'info', '/Mazars/iconinfo.png'))
    scene1.add(this.addSprite(new THREE.Vector3(1, -0.5, 0.5), 'changeToScene2', 'rdc OpenSpace', ''))

    const nextScene = scene1
    const prevScene = this.currentScene
    const position = Camera_Positions[1]
    var Scene_Name = Scene_Names[1].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene0() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene0 = new THREE.Scene()
    scene0.name = 'rdcWelcome'
    scene0.add(this.createSphere(this.rdcWelcome))
    scene0.add(this.addSprite(new THREE.Vector3(0.32, -0.37, -0.7), 'changeToScene1', 'rdc Hall', ''))
    scene0.add(this.addSprite(new THREE.Vector3(15, 3, 0), 'clickedToPlay1', 'info', '/Mazars/iconinfo.png'))
    scene0.add(this.addSprite(new THREE.Vector3(15, 1.5, 0), 'clickedToQuizz0', 'quizz', '/Mazars/iconquiz.png'))

    const nextScene = scene0
    const prevScene = this.currentScene
    const position = Camera_Positions[0]
    var Scene_Name = Scene_Names[0].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene2() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene2 = new THREE.Scene()

    scene2.name = 'rdc openSpace'
    scene2.add(this.createSphere(this.rdcOpenSpace))
    scene2.add(this.addSprite(new THREE.Vector3(0.4, 0.01, 0.3), 'changeToScene1', 'rdc Hall', ''))
    scene2.add(this.addSprite(new THREE.Vector3(-12, 0.5, -20), 'clickedToPlay3', 'Département Outsourcing', '/Mazars/iconinfo.png'))
    scene2.add(this.addSprite(new THREE.Vector3(-16, 0.5, -20), 'clickedToQuizz3', 'quizz', '/Mazars/iconquiz.png'))
    scene2.add(this.addSprite(new THREE.Vector3(-2.8, -0.14, -6), 'changeToScene3', 'rdc openSpaceHall', ''))

    const nextScene = scene2
    const prevScene = this.currentScene
    const position = Camera_Positions[2]
    var Scene_Name = Scene_Names[2].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene3() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene3 = new THREE.Scene()
    scene3.name = 'rdc openSpaceHall'
    scene3.add(this.createSphere(this.rdcOpenSpaceHall))
    scene3.add(this.addSprite(new THREE.Vector3(0.15, -0.55, 0.6), 'changeToScene2', 'rdc OpenSpace', ''))
    scene3.add(this.addSprite(new THREE.Vector3(0.22, -0.05, -0.3), 'changeToScene4', 'rdc Outside', ''))
    scene3.add(this.addSprite(new THREE.Vector3(-0.8, 0.7, -20), 'clickedToPlay4', 'info', '/Mazars/iconinfo.png'))
    scene3.add(this.addSprite(new THREE.Vector3(-0.3, -0.5, -1), 'changeToScene5', 'rdc MeetingRoom', ''))

    const nextScene = scene3
    const prevScene = this.currentScene
    const position = Camera_Positions[3]
    var Scene_Name = Scene_Names[3].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene4() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene4 = new THREE.Scene()
    scene4.name = 'rdc OutSide'
    scene4.add(this.createSphere(this.rdcOutSide))
    scene4.add(this.addSprite(new THREE.Vector3(0.65, 0, -1), 'changeToScene3', 'rdc OpenSpaceHall', ''))
    scene4.add(this.addSprite(new THREE.Vector3(-16, 1.5, 10), 'clickedToPlay5', 'info', '/Mazars/iconinfo.png'))
    scene4.add(this.addSprite(new THREE.Vector3(-20, 1.5, 10), 'clickedToQuizz4', 'quizz', '/Mazars/iconquiz.png'))

    const nextScene = scene4
    const prevScene = this.currentScene
    const position = Camera_Positions[4]
    var Scene_Name = Scene_Names[4].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene5() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene5 = new THREE.Scene(this.camera.position.set(14, 10, -50))
    scene5.name = 'rdc meetingRoom'
    scene5.add(this.createSphere(this.rdcMeetingRoom))
    scene5.add(this.addSprite(new THREE.Vector3(0, 0, -1), 'changeToScene3', 'rdc OpenSpaceHall', ''))
    scene5.add(this.addSprite(new THREE.Vector3(16, 5.15, -10.5), 'clickedToPlay6', 'info', '/Mazars/iconinfo.png'))
    scene5.add(this.addSprite(new THREE.Vector3(19, 5.5, -6), 'clickedToQuizz5', 'quizz', '/Mazars/iconquiz.png'))
    scene5.add(this.addSprite(new THREE.Vector3(16, 4.85, -7.5), 'clickedToInterVideos0', 'play', '/Mazars/iconplay.png'))
    const nextScene = scene5
    const prevScene = this.currentScene
    const position = Camera_Positions[5]
    var Scene_Name = Scene_Names[5].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene6() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene6 = new THREE.Scene()
    scene6.name = 'first Welcome'
    scene6.add(this.createSphere(this.firstWelcome))
    scene6.add(this.addSprite(new THREE.Vector3(0.3, 0.2, 1), 'changeToScene7', 'first office1', ''))
    scene6.add(this.addSprite(new THREE.Vector3(-1, -0.55, -0.3), 'changeToScene8', 'first openSpace1', ''))
    scene6.add(this.addSprite(new THREE.Vector3(1.45, -0.35, -0.95), 'changeToScene10', 'first office2', ''))
    scene6.add(this.addSprite(new THREE.Vector3(0, 3, 20), 'clickedToQuizz6', 'quizz', '/Mazars/iconquiz.png'))
    scene6.add(this.addSprite(new THREE.Vector3(-2, 3, 20), 'clickedToPlay7', 'info', '/Mazars/iconinfo.png'))

    const nextScene = scene6
    const prevScene = this.currentScene
    const position = Camera_Positions[6]
    var Scene_Name = Scene_Names[6].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene7() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene7 = new THREE.Scene()
    scene7.name = 'first office1'
    scene7.add(this.createSphere(this.firstOffice1))
    scene7.add(this.addSprite(new THREE.Vector3(0.9, -0.2, -1), 'changeToScene6', 'first Welcome', ''))
    scene7.add(this.addSprite(new THREE.Vector3(5.5, 3.5, 17), 'clickedToInterVideos1', 'play', '/Mazars/iconplay.png'))
    const nextScene = scene7
    const prevScene = this.currentScene
    const position = Camera_Positions[7]
    var Scene_Name = Scene_Names[7].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene8() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene8 = new THREE.Scene()
    scene8.name = 'first openSpace1'
    scene8.add(this.createSphere(this.firstOpenSpace1))
    scene8.add(this.addSprite(new THREE.Vector3(-0.1, -0.25, -1), 'changeToScene6', 'first Welcome', ''))
    scene8.add(this.addSprite(new THREE.Vector3(-0.8, 0, 0.35), 'changeToScene9', 'first openSpace2', ''))
    scene8.add(this.addSprite(new THREE.Vector3(-15, -1.5, 22), 'clickedToQuizz7', 'quizz', '/Mazars/iconquiz.png'))
    scene8.add(this.addSprite(new THREE.Vector3(-15, 1, 22), 'clickedToPlay8', 'Le département Audit', '/Mazars/iconinfo.png'))

    const nextScene = scene8
    const prevScene = this.currentScene
    const position = Camera_Positions[8]
    var Scene_Name = Scene_Names[8].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene9() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene9 = new THREE.Scene()
    scene9.name = 'first openSpace2'
    scene9.add(this.createSphere(this.firstOpenSpace2))
    scene9.add(this.addSprite(new THREE.Vector3(-0.24, -0.15, -1), 'changeToScene8', 'first openSpace1 ', ''))
    scene9.add(this.addSprite(new THREE.Vector3(14.5, 5.5, -32), 'clickedToQuizz8', 'quizz', '/Mazars/iconquiz.png'))
    scene9.add(this.addSprite(new THREE.Vector3(14.5, 5, -25), 'clickedToInterVideos2', 'play', '/Mazars/iconplay.png'))
    scene9.add(this.addSprite(new THREE.Vector3(15.5, 4.5, -20.5), 'clickedToPlay9', 'info', '/Mazars/iconinfo.png'))

    const nextScene = scene9
    const prevScene = this.currentScene
    const position = Camera_Positions[9]
    var Scene_Name = Scene_Names[9].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene10() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene10 = new THREE.Scene()
    scene10.name = 'first office2'
    scene10.add(this.createSphere(this.firstOffice2))
    scene10.add(this.addSprite(new THREE.Vector3(0.4, -0.1, 1), 'changeToScene6', 'first Welcome', ''))
    scene10.add(this.addSprite(new THREE.Vector3(1.8, 4, -15), 'clickedToQuizz9', 'quizz', '/Mazars/iconquiz.png'))
    scene10.add(this.addSprite(new THREE.Vector3(0, 4, -15), 'clickedToInterVideos3', 'play', '/Mazars/iconplay.png'))
    
    const nextScene = scene10
    const prevScene = this.currentScene
    const position = Camera_Positions[10]
    var Scene_Name = Scene_Names[10].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene11() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene11 = new THREE.Scene()
    scene11.name = 'second welcome'
    scene11.add(this.createSphere(this.secondWelcome))
    scene11.add(this.addSprite(new THREE.Vector3(0.1, -0.8, 1), 'changeToScene13', 'second meetingRoom', ''))
    scene11.add(this.addSprite(new THREE.Vector3(18, 0.8, 22), 'clickedToPlay10', 'Mazars', '/Mazars/iconinfo.png'))

    const nextScene = scene11
    const prevScene = this.currentScene
    const position = Camera_Positions[11]
    var Scene_Name = Scene_Names[11].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene12() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene12 = new THREE.Scene()
    scene12.name = 'second openSpace'
    scene12.add(this.createSphere(this.secondOpenSPace))
    scene12.add(this.addSprite(new THREE.Vector3(0.2, -0.30, -1), 'changeToScene14', 'third outside', ''))
    scene12.add(this.addSprite(new THREE.Vector3(-20, 4.5, 3.2), 'clickedToQuizz11', 'quizz', '/Mazars/iconquiz.png'))
    scene12.add(this.addSprite(new THREE.Vector3(-20, 4.5, 5.7), 'clickedToInterVideos5', 'play', '/Mazars/iconplay.png'))
    scene12.add(this.addSprite(new THREE.Vector3(-20, 4.5, 7.9), 'clickedToPlay11', 'Conseil Financier et RH', '/Mazars/iconinfo.png'))

    const nextScene = scene12
    const prevScene = this.currentScene
    const position = Camera_Positions[12]
    var Scene_Name = Scene_Names[12].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene13() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene13 = new THREE.Scene()
    scene13.name = 'second meetingRoom'
    scene13.add(this.createSphere(this.secondMeetingRoom))
    scene13.add(this.addSprite(new THREE.Vector3(-0.15, 0, -1), 'changeToScene11', 'second Welcome', ''))
    scene13.add(this.addSprite(new THREE.Vector3(25, 6.7, 15.9), 'clickedToQuizz10', 'quizz', '/Mazars/iconquiz.png'))
    scene13.add(this.addSprite(new THREE.Vector3(25, 6.3, 13), 'clickedToInterVideos4', 'play', '/Mazars/iconplay.png'))

    const nextScene = scene13
    const prevScene = this.currentScene
    const position = Camera_Positions[13]
    var Scene_Name = Scene_Names[13].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  changeToScene14() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const scene14 = new THREE.Scene()
    scene14.name = 'third outside'
    scene14.add(this.createSphere(this.thirdOutSide))
    scene14.add(this.addSprite(new THREE.Vector3(-4, -0.5, 8), 'changeToScene12', 'second Welcome', ''))

    const nextScene = scene14
    const prevScene = this.currentScene
    const position = Camera_Positions[14]
    var Scene_Name = Scene_Names[14].Scene_Name
    console.log(position.position.x, position.position.y, position.position.z)
    this.sendStats('scene', Scene_Name)
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSceneSprites(nextScene)
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
          prevScene.destroy()
        }
      })
    }
  }

  // Function to move to the Next scene
  goToNextScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex + 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // Function to move to the previous scene
  goToPrevScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex - 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      TweenLite.to(environmentSphere.material, 1, {
        opacity: 0,
        onComplete: () => {
          this.currentScene = nextScene
          const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          newCurrentSceneSphere.material.opacity = 0
          TweenLite.to(newCurrentSceneSphere.material, 1, {
            opacity: 1,
            onComplete: () => {}
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // remove sprite after moving to another scene
  removeSprites = (sceneIndex) => {
    this.EventBus.$emit('i-got-hover-out')
    const sprites = this.currentScene.children.filter(o => o.type === 'Sprite')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const nextSceneSprites = nextScene.children.filter(o => o.type === 'Sprite')
    sprites.map(s => {
      TweenLite.to(s.scale, 1, {
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          nextSceneSprites.map(ns => {
            TweenLite.to(ns.scale, 1, {
              x: 4,
              y: 4,
              z: 4,
              onComplete: () => {}
            })
          })
        }
      })
    })
  }

  removeSceneSprites = (newScene) => {
    this.EventBus.$emit('i-got-hover-out')
    const sprites = this.currentScene.children.filter(o => o.type === 'Sprite')
    const nextScene = newScene
    const nextSceneSprites = nextScene.children.filter(o => o.type === 'Sprite')
    sprites.map(s => {
      TweenLite.to(s.scale, 1, {
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          nextSceneSprites.map(ns => {
            TweenLite.to(ns.scale, 1, {
              x: 4,
              y: 4,
              z: 4,
              onComplete: () => {}
            })
          })
        }
      })
    })
  }

  clickedToQuizz(Q) {
    const questionIndex = Q
    console.log('QI', questionIndex)
    localStorage.setItem('questionIndex', questionIndex)
    return questionIndex
  }

  init() {
    // initiate camera position
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1100)
    this.camera.target = new THREE.Vector3(0, 0, 0)
    this.camera.position.set(0, 0, 0)

    this.langue = localStorage.getItem('langue')

    // Creating scene 0 and adding button to interact with
    const scene0 = new THREE.Scene()
    scene0.name = 'rdcWelcome'
    scene0.add(this.createSphere(this.rdcWelcome))
    scene0.add(this.addSprite(new THREE.Vector3(0.32, -0.37, -0.7), 'changeToScene1', 'rdc Hall', ''))
    scene0.add(this.addSprite(new THREE.Vector3(15, 3, 0), 'clickedToPlay1', 'info', '/Mazars/iconinfo.png'))
    scene0.add(this.addSprite(new THREE.Vector3(15, 1.5, 0), 'clickedToQuizz0', 'quizz', '/Mazars/iconquiz.png'))
    /* this.scenes.push(scene0) */

    // initialize the camera position into scene 0
    this.currentScene = scene0
    this.container.appendChild(this.renderer.domElement)
    // init controle to move around
    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = -0.5
    this.controls.enableZoom = true
    this.controls.enablePan = false
    this.controls.maxDistance = 2
    this.camera.position.set(-80, 1, -40)
    this.controls.update()
    this.container.addEventListener('mousemove', this.onMouseMove, false)
    this.container.addEventListener('click', event => {
      const x = (event.clientX / window.innerWidth) * 2 - 1
      const y = -(event.clientY / window.innerHeight) * 2 + 1
      this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
      const intersects = this.raycaster.intersectObjects(this.currentScene.children)
      intersects.forEach(element => {
        console.log(element.object.name)
      })

      const intersectsObjects = intersects.filter(i => i.object.type === 'Sprite' || i.object.type === 'area' || i.object.type === 'Mesh')
      if (intersectsObjects) {
        intersectsObjects.map(io => {
          console.log('clicked' + io.object.name)
          if (io.object.name === 'nextScene') {
            this.goToNextScene()
          } else if (io.object.name === 'prevScene') {
            this.goToPrevScene()
          } else if (io.object.name === 'changeToScene1') {
            this.changeToScene1()
          } else if (io.object.name === 'changeToScene0') {
            this.changeToScene0()
          } else if (io.object.name === 'changeToScene2') {
            this.changeToScene2()
          } else if (io.object.name === 'changeToScene3') {
            this.changeToScene3()
          } else if (io.object.name === 'changeToScene4') {
            this.changeToScene4()
          } else if (io.object.name === 'changeToScene5') {
            this.changeToScene5()
          } else if (io.object.name === 'changeToScene6') {
            this.changeToScene6()
          } else if (io.object.name === 'changeToScene7') {
            this.changeToScene7()
          } else if (io.object.name === 'changeToScene8') {
            this.changeToScene8()
          } else if (io.object.name === 'changeToScene9') {
            this.changeToScene9()
          } else if (io.object.name === 'changeToScene10') {
            this.changeToScene10()
          } else if (io.object.name === 'changeToScene11') {
            this.changeToScene11()
          } else if (io.object.name === 'changeToScene12') {
            this.changeToScene12()
          } else if (io.object.name === 'changeToScene13') {
            this.changeToScene13()
          } else if (io.object.name === 'changeToScene14') {
            this.changeToScene14()
          } else if (io.object.name.slice(0, -1) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-1))
          } else if (io.object.name.slice(0, -2) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-2))
          } else if (io.object.name.slice(0, -1) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 1),
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 2),
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -1) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-1))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-2))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-2))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name === 'clickedToGoPage') {
            this.EventBus.$emit('clickedToGoPage', {
              url: '/voting',
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -1) === 'clickedToInterVideos') {
            this.EventBus.$emit('clickedToInterVideos', {
              videoIndex: +io.object.name.substr(io.object.name.length - 1),
              io,
              mouseCoordinates: { x: event.clientX, y: event.clientY }
            })
          }
        })
      }
    })

    this.animate()
  }

  animate = () => {
    requestAnimationFrame(this.animate)
    this.update()
  }

  update = () => {
    this.renderer.render(this.currentScene, this.camera)
  }

  // making the sphere Responsive
  onResize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
  }

  onMouseMove = event => {
    /* const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    ) */
    const x = (event.clientX / window.innerWidth) * 2 - 1
    const y = -(event.clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
    const intersects = this.raycaster.intersectObjects(this.currentScene.children)
    const intersectsSprite = intersects.find(i => i.object.type === 'Sprite' || i.object.type === 'Mesh')

    if (intersectsSprite && !this.emitSpriteHoverIn && intersectsSprite.object.name !== '' && intersectsSprite.object.name !== 'environmentSphere') {
      const p = intersectsSprite.object.position.clone().project(this.camera)
      this.EventBus.$emit('i-got-hover-in', {
        sprite: intersectsSprite,
        mouseCoordinates: {
          y: ((-1 * p.y + 1) * window.innerHeight) / 2,
          x: ((p.x + 1) * window.innerWidth) / 2
        },
        popoverContent: intersectsSprite.object.popoverContent
      })
      this.emitSpriteHoverIn = true
      this.emitSpriteHoverOut = false
    } else if ((!intersectsSprite && !this.emitSpriteHoverOut) || (intersectsSprite && (intersectsSprite.object.name === '' || intersectsSprite.object.name === 'environmentSphere'))) {
      this.EventBus.$emit('i-got-hover-out')
      this.emitSpriteHoverOut = true
      this.emitSpriteHoverIn = false
    }
  }

  addPoint(sceneIndex = 0) {
    const map = new THREE.TextureLoader().load('https://images-na.ssl-images-amazon.com/images/I/41Y4fyn7HAL.png')
    const arrowMaterial = new THREE.SpriteMaterial({
      map: map
    })
    arrowMaterial.transparent = true
    const point = new THREE.Vector3(14, 1.9, -47)
    const sprite = new THREE.Sprite(arrowMaterial)
    sprite.name = 'enter'
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(20)
    )
    this.scenes[sceneIndex].add(sprite)
    this.points.push(point)
  }

  destroy() {
    this.camera = null
    this.currentScene = null
    this.scenes = []
    this.renderer = null
    this.points = []
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
  }

  EventBus = new Vue()
}
