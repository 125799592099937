<template>
  <div>
    <audio
      id="myAudio"
      ref="audio"
      autoplay
      playsinline
      webkit-playsinline
      style="display: none"
      src="../assets/Whip-Yung-Logos.mp3"
    ></audio>
    <a href="/">
      <img class="lg" src="@/assets/logo_mazars.png" alt="" />
    </a>
    <loader v-if="!isLoaded"></loader>
    <div ref="sceneContainer" style="width: 100vw; height: 100vh"></div>
    <div
      class="popover fade bs-popover-top"
      :class="popover.show ? 'show' : ''"
      role="tooltip"
      id="popover246408"
      x-placement="top"
      :style="`pointer-events:none;
         background: linear-gradient(34deg, rgba(110,110,110,0.6278886554621849) 19%, rgba(255,255,255,0.7315301120448179) 78%);
         min-width: 8rem;
         line-height: 1.5rem;
         font-size: 1.1rem;
         position: absolute;
         will-change: transform;
         top: 0px;
         left: 0px;
         transform: translate3d(${popover.position.x - 90}px, ${popover.position.y - 50}px, 0px);`"
    >
      <div class="arrow"></div>
      <div class="popover-body text-center" style="color: white">{{ popover.popoverContent }}</div>
    </div>
    <a-modal
      v-model="visibleModalPlay"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :body-style="{ padding: 0 }"
    >
      <div
        class="some-modal-content"
        style="
          background: linear-gradient(
            34deg,
            rgba(110, 110, 110, 0.6278886554621849) 19%,
            rgba(255, 255, 255, 0.7315301120448179) 78%
          );
        "
      >
        <AwesomeSlider
          class="info"
          v-if="version !== 'Visual'"
          :slides="slides"
          :height="'250px'"
          :parallax="false"
          :content="[]"
        />
        <div class="p-3 text-white">
          <div class="mb-3 mt-1 d-flex">
            <div class="flex-grow-1">
              <h4 class="mb-0 mt-2 text-white" v-html="modalH4"></h4>
            </div>
          </div>
          <hr size="50" noshade style="color: white" />
          <p class="text-left" v-html="modalContent"></p>
        </div>
        <div class="buttons text-center text-white">
          <a-button class="buttons text-center text-white" @click="closeModalPlay" shape="circle" icon="close" />
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="visibleModalQuizz"
      centered
      :closable="false"
      :maskClosable="false"
      :labelAlign="'left'"
      :footer="null"
      :body-style="{ padding: 0 }"
      width="640px"
      style="top: 70px"
    >
      <div v-show="showPopup" class="some-modal-content form-quizz">
        <div class="bg-white">
          <div class="poquiz">
            <div class="buttons text-center text-white">
              <a-button class="closebutton" @click="closeQuiz" shape="circle" icon="close" />
            </div>
            <div class="mt-0 py-0 clearfix">
              <div v-show="this.userServerId == 0" class="px-3">
                <img
                  class="w-25 h-25"
                  style="display: block; margin-left: auto; margin-right: auto"
                  src="../assets/logo_mazars.png"
                  alt=""
                />
                <h2
                  class="border-bottom mb-4 pb-3 pt-3 text-center"
                  style="color: #0071ce; font-family: Roboto; text-transform: uppercase; font-weight: 700"
                >
                  i n s c r i p t i o n
                </h2>
                <a-form-model
                  ref="quizzFormRef"
                  style="color: #0071ce"
                  :model="formQuizz"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  :rules="formQuizzRules"
                  :labelAlign="'left'"
                  :layout="'vertical'"
                >
                  <a-form-model-item
                    class="py-0"
                    label="Nom"
                    style="font-family: Roboto; color: #0071ce"
                    prop="FirstName"
                    placeholder="Veuillez sélectionner votre nom"
                  >
                    <a-input v-model="formQuizz.FirstName" />
                  </a-form-model-item>
                  <a-form-model-item
                    class="py-0"
                    label="Prénom"
                    style="font-family: Roboto; color: #0071ce"
                    prop="LastName"
                    placeholder="Veuillez sélectionner votre prénom"
                  >
                    <a-input v-model="formQuizz.LastName" />
                  </a-form-model-item>
                  <a-form-model-item
                    style="font-family: Roboto; color: #0071ce"
                    label="sexe"
                    :labelAlign="'left'"
                    class="sex-form-control"
                    prop="gender"
                    :wrapper-col="{ span: 14, offset: 0 }"
                  >
                    <a-radio-group v-model="formQuizz.gender" style="color: #0071ce">
                      <a-radio style="font-family: Roboto; color: #0071ce; font-weight: 500" value="homme"
                        >Homme</a-radio
                      >
                      <a-radio style="font-family: Roboto; color: #0071ce; font-weight: 500" value="femme"
                        >Femme</a-radio
                      >
                    </a-radio-group>
                  </a-form-model-item>
                  <a-form-model-item style="font-family: Roboto; color: #0071ce" label="âge" prop="age">
                    <a-input-number v-model="formQuizz.age" />
                  </a-form-model-item>
                  <a-form-model-item style="font-family: Roboto; color: #0071ce" label="pays" prop="region">
                    <a-select v-model="formQuizz.region" placeholder="Veuillez sélectionner votre pays">
                      <a-select-option v-for="gouv in gouvs" :key="gouv.name" :title="gouv.name">{{
                        gouv.name
                      }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item class="row d-flex justify-content-center">
                    <a-button
                      class="col-5 text-center"
                      style="
                        margin-right: 10%;
                        background-color: #0071ce;
                        color: white;
                        border-radius: 5px;
                        font-family: Roboto;
                        padding-right: 3px;
                      "
                      @click="onSubmit"
                      >Continuer</a-button
                    >

                    <a-button
                      class="col-5 text-center"
                      style="font-family: Roboto; border-color: #0071ce; color: #0071ce; border-radius: 5px"
                      @click="$refs.quizzFormRef.resetFields()"
                      >Réinitialiser</a-button
                    >
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div
                v-if="
                  this.userServerId != 0 &&
                  quizzCurrent < quizzSteps.length &&
                  quizzSteps.length > 0 &&
                  ((questionIndex == 0 && quizzCurrent < 3) ||
                    (questionIndex == 3 && quizzCurrent < 4) ||
                    (questionIndex == 4 && quizzCurrent < 5) ||
                    (questionIndex == 5 && quizzCurrent < 6) ||
                    (questionIndex == 6 && quizzCurrent < 7) ||
                    (questionIndex == 7 && quizzCurrent < 8) ||
                    (questionIndex == 8 && quizzCurrent < 9) ||
                    (questionIndex == 9 && quizzCurrent < 10) ||
                    (questionIndex == 10 && quizzCurrent < 11) ||
                    (questionIndex == 11 && quizzCurrent < 12))
                "
              >
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-sm-10 w-10 h-10">
                      <img class="logo" src="../assets/logo_mazars.png" alt="" />
                    </div>
                    <div class="col-sm-2 compteur">
                      <span>question:{{ quizzCurrent + 1 }}/{{ quizzSteps.length }}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-4 px-4">
                  <div v-if="quizzCurrent < quizzSteps.length" :key="this.questionIndex">
                    <h3
                      dir="ltr"
                      class="mb-3 text-center px-4"
                      style="color: #0071ce; font-weight: 800px; font-family: Roboto"
                      v-html="quizzSteps[quizzCurrent].questionText"
                      :key="questionIndex"
                    ></h3>
                    <div class="line"></div>
                    <div class="px-4">
                      <a-button
                        dir="ltr"
                        v-for="(answer, answerIndex) in quizzSteps[quizzCurrent].answers"
                        :key="answerIndex"
                        size="large"
                        @click="nextQuizz($event, answerIndex, quizzCurrent)"
                        block
                        class="mb-2 px-4 answer animate__animated"
                        >{{ answerIndex + 1 }} - {{ answer.answerText }}</a-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="steps-action text-center px-4"
                v-if="
                  quizzCurrent == quizzSteps.length ||
                  (quizzCurrent > 2 && questionIndex == 0) ||
                  (quizzCurrent > 3 && questionIndex == 3) ||
                  (quizzCurrent > 4 && questionIndex == 4) ||
                  (quizzCurrent > 5 && questionIndex == 5) ||
                  (quizzCurrent > 6 && questionIndex == 6) ||
                  (quizzCurrent > 7 && questionIndex == 7) ||
                  (quizzCurrent > 8 && questionIndex == 8) ||
                  (quizzCurrent > 9 && questionIndex == 9) ||
                  (quizzCurrent > 10 && questionIndex == 10) ||
                  (quizzCurrent > 11 && questionIndex == 11)
                "
              >
                <a-result title="Super, vous avez répondu à toutes les questions">
                  <template #icon>
                    <a-icon type="like" theme="twoTone" />
                  </template>
                  <template #extra>
                    <a-button style="background-color: #0071ce; color: white" @click="closeQuiz">Terminer</a-button>
                  </template>
                </a-result>
              </div>
            </div>
            <div class="loading-bar"></div>
          </div>
        </div>
      </div>
    </a-modal>
    <video id="video" ref="videoChroma" muted autoplay playsinline webkit-playsinline style="display: none"></video>
    <video id="play" muted loop autoplay playsinline webkit-playsinline style="display: none"></video>
    <video id="info" muted loop autoplay playsinline webkit-playsinline style="display: none"></video>
    <video id="quizz" muted loop autoplay playsinline webkit-playsinline style="display: none"></video>
    <video id="stop" muted loop autoplay playsinline webkit-playsinline style="display: none"></video>
    <div class="header">
      <div class="row etage">
        <div class="col my-auto">
          <a class="row buttonc" v-on:click="showPopupp"></a>
          <a class="row ml-3 mt-5" @click="movetoscene(12, '3')"><img :src="src3" /></a>
          <a class="row ml-3 mt-3" @click="movetoscene(11, '2')"><img :src="src2" /></a>
          <a class="row ml-3 mt-3" @click="movetoscene(6, '1')"><img :src="src1" /></a>
          <a class="row ml-3 mt-3" @click="movetoscene(0, '0')"><img :src="src0" /></a>
        </div>
      </div>
      <a-modal
        v-model="isOpen"
        :closable="false"
        :body-style="{ padding: 0 }"
        :footer="null"
        width="800px"
        height="500px"
      >
        <div class="plan-popup">
          <img src="../assets/tutoriel.jpg" />
        </div>
      </a-modal>
      <div class="navigation">
        <a href="mailto:rh@mazars.tn" class="buttonl"></a>
        <a-button class="buttonq" @click="showModalx"></a-button>
        <a-modal
          v-model="isModalVisible"
          :closable="false"
          :maskClosable="false"
          centered
          :footer="null"
          :body-style="{ padding: 0 }"
          width="700px"
        >
          <div class="mt-6">
            <div class="statpopup">
              <div class="buttons text-center text-white">
                <a-button type="button" class="closestat" @click="closeModal" shape="circle" icon="close" />
              </div>
              <div v-if="enquetteCurrent < enquetteSteps.length && enquetteSteps.length > 0">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-sm-10 w-25 h-10">
                      <img class="logo" src="../assets/logo_mazars.png" alt="" />
                    </div>
                    <div class="col-sm-2 compteur">
                      <span>question:{{ enquetteCurrent + 1 }}/{{ enquetteSteps.length }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="enquetteCurrent < enquetteSteps.length">
                  <h3
                    dir="ltr"
                    class="mt-5 mr-20 text-center"
                    style="color: #0071ce; font-weight: 800px; font-family: Roboto"
                    v-html="enquetteSteps[enquetteCurrent].questionText"
                  ></h3>
                  <div class="line"></div>
                  <div v-if="enquetteSteps[enquetteCurrent].isFreeAnswer">
                    <a-form-model-item
                      style="font-family: Roboto; color: #0071ce"
                      prop="reponseE"
                      placeholder="Suggestion"
                    >
                      <textarea class="textarea" rows="4" cols="80" v-model="freeResponse" />
                    </a-form-model-item>
                    <a-button class="bouttonEnv" @click="nextEnquette($event, 'Free', enquetteCurrent)"
                      >Envoyer</a-button
                    >
                  </div>
                  <div v-else>
                    <div v-if="enquetteCurrent == 0 || enquetteCurrent == 1">
                      <div class="mt-4 ml-4 pb-4">
                        <a-button class="tr ml-4" @click="nextEnquette($event, 'Excellente', enquetteCurrent)"
                          ><span class="enqResp">Excellente</span></a-button
                        >
                        <a-button class="sat ml-4" @click="nextEnquette($event, 'Très bien', enquetteCurrent)"
                          ><span class="enqResp">Très bien</span></a-button
                        >
                        <a-button class="neu ml-4" @click="nextEnquette($event, 'Bien', enquetteCurrent)"
                          ><span class="enqResp">Bien</span></a-button
                        >
                        <a-button class="in_o ml-4" @click="nextEnquette($event, 'Moyenne', enquetteCurrent)"
                          ><span class="enqResp">Moyenne</span></a-button
                        >
                        <a-button class="trin ml-4" @click="nextEnquette($event, 'À améliorer', enquetteCurrent)"
                          ><span class="enqResp">À améliorer</span></a-button
                        >
                      </div>
                    </div>
                    <div v-else-if="enquetteCurrent == 2">
                      <div class="mt-4 ml-4 pb-4">
                        <a-button
                          class="tr ml-4"
                          @click="nextEnquette($event, 'Extrêmement satisfait', enquetteCurrent)"
                          ><span class="enqResp">Extrêmement <br />satisfait</span></a-button
                        >
                        <a-button class="sat ml-4" @click="nextEnquette($event, 'Satisfait', enquetteCurrent)"
                          ><span class="enqResp">Satisfait</span></a-button
                        >
                        <a-button class="neu ml-4" @click="nextEnquette($event, 'Neutre', enquetteCurrent)"
                          ><span class="enqResp">Neutre</span></a-button
                        >
                        <a-button class="in_o ml-4" @click="nextEnquette($event, 'Insatisfait', enquetteCurrent)"
                          ><span class="enqResp">Insatisfait</span></a-button
                        >
                        <a-button
                          class="trin ml-4"
                          @click="nextEnquette($event, 'Extrêmement insatisfait', enquetteCurrent)"
                          ><span class="enqResp">Extrêmement <br />insatisfait</span></a-button
                        >
                      </div>
                    </div>
                    <div v-else-if="enquetteCurrent == 3">
                      <div class="mt-4 ml-4 pb-4">
                        <a-button class="tr ml-4" @click="nextEnquette($event, 'Tout à fait d’accord', enquetteCurrent)"
                          ><span class="enqResp">Tout à fait <br />d’accord</span></a-button
                        >
                        <a-button class="sat ml-4" @click="nextEnquette($event, 'D’accord', enquetteCurrent)"
                          ><span class="enqResp">D’accord</span></a-button
                        >
                        <a-button class="neu ml-4" @click="nextEnquette($event, 'Neutre', enquetteCurrent)"
                          ><span class="enqResp">Neutre</span></a-button
                        >
                        <a-button class="in_o ml-4" @click="nextEnquette($event, 'Pas d’accord', enquetteCurrent)"
                          ><span class="enqResp">Pas d’accord</span></a-button
                        >
                        <a-button
                          class="trin ml-4"
                          @click="nextEnquette($event, 'Pas du tout d’accord', enquetteCurrent)"
                          ><span class="enqResp">Pas du tout <br />d’accord</span></a-button
                        >
                      </div>
                    </div>
                    <div v-else-if="enquetteCurrent == 4">
                      <div class="mt-4 ml-4 pb-4">
                        <a-button class="tr ml-4" @click="nextEnquette($event, 'Très probable', enquetteCurrent)"
                          ><span class="enqResp">Très probable</span></a-button
                        >
                        <a-button class="sat ml-4" @click="nextEnquette($event, 'Probable', enquetteCurrent)"
                          ><span class="enqResp">Probable</span></a-button
                        >
                        <a-button class="neu ml-4" @click="nextEnquette($event, 'Neutre', enquetteCurrent)"
                          ><span class="enqResp">Neutre</span></a-button
                        >
                        <a-button class="in_o ml-4" @click="nextEnquette($event, 'Peu probable', enquetteCurrent)"
                          ><span class="enqResp">Peu probable</span></a-button
                        >
                        <a-button class="trin ml-4" @click="nextEnquette($event, 'Très peu probable', enquetteCurrent)"
                          ><span class="enqResp">Très peu <br />probable</span></a-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="steps-action text-center" v-if="enquetteCurrent >= enquetteSteps.length">
                <a-result title="Super, vous avez répondu à toutes les questions">
                  <template #icon>
                    <a-icon type="like" theme="twoTone" />
                  </template>
                  <template #extra>
                    <a-button style="background-color: #0071ce; color: white" @click="isModalVisible = false"
                      >Terminer</a-button
                    >
                  </template>
                </a-result>
              </div>
              <div class="loading-bar"></div>
            </div>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler'

import {
  MODAL_CONENT_1,
  MODAL_CONENT_2,
  MODAL_CONENT_3,
  MODAL_CONENT_4,
  MODAL_CONENT_5,
  MODAL_CONENT_6,
  MODAL_CONENT_7,
  MODAL_CONENT_8,
  MODAL_CONENT_9,
  MODAL_CONENT_10,
  MODAL_CONENT_11,
  GOUVS,
  API_URL,
  Scene_Names
} from '@/.env'
import Loader from '@/components/slider/Loader.vue'
import { QuizzService } from '@/services/quizz.service'
import { ReportingService } from '@/services/reporting.service'
import AwesomeSlider from '@/components/slider/AwesomeSlider.vue'
import { v4 as uuid } from 'uuid'
import { Scene } from '../thirdParty/index.js'
import src3off from '@/assets/etage/3_off.png'
import src3on from '@/assets/etage/3_on.png'
import src2off from '@/assets/etage/2_off.png'
import src2on from '@/assets/etage/2_on.png'
import src1off from '@/assets/etage/1_off.png'
import src1on from '@/assets/etage/1_on.png'
import src0off from '@/assets/etage/0_off.png'
import src0on from '@/assets/etage/0_on.png'

export default {
  name: 'Mazars',
  components: {
    AwesomeSlider,
    Loader
  },

  el: '#app',
  data() {
    return {
      quiz: false,
      src3: src3off,
      src2: src2off,
      src1: src1off,
      src0: src0on,
      userServerId: 0,
      questionIndex: 0,
      isOpen: false,
      stat: false,
      isModalVisible: false,
      showModal: false, // added by me
      isLoaded: false,
      version: 'Audio-Visual',
      loadingQuizz: true,
      visibleModalImage: false,
      visibleModalPlay: false,
      visibleModalQuizz: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      gouvs: GOUVS,
      freeResponse: '',
      formQuizz: {
        FirstName: '',
        LastName: '',
        region: undefined,
        age: '',
        gender: ''
      },
      formQuizzRules: {
        FirstName: [{ required: false, message: "S'il vous plaît écrivez ton nom", trigger: 'blur' }],
        LastName: [{ required: false, message: "S'il vous plaît écrivez ton prénom", trigger: 'blur' }],
        age: [
          { required: true, message: "S'il vous plaît écrivez l'âge", trigger: 'blur' },
          { type: 'number', message: "l'âge en chiffres", trigger: 'blur' }
        ],
        region: [{ required: false, message: 'Veuillez sélectionner votre pays', trigger: 'change' }],
        gender: [{ required: true, message: 'Veuillez sélectionner un sexe', trigger: 'change' }]
      },
      playerOptions: {
        // videojs options
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '/videos/interactive/2_into.webm'
          }
        ]
      },
      visibleModalInterVideosChoice: false,
      playerInterVideosOptions: {
        // videojs options
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '/videos/interactive/2_into.webm'
          }
        ]
      },
      currentInterVideos: {},
      quizzCurrent: 0,
      quizzSteps: [],
      enquetteCurrent: 0,
      enquetteSteps: [],
      inPlay: false,
      player: null,
      playPauseIcon: 'caret-right',
      slides: [],
      imageMuseum: [],
      loading: false,
      ThreeScene: null,
      popover: {
        show: false,
        popoverContent: '',
        position: {
          x: 0,
          y: 0
        }
      },
      modalH4: 'modal title H4',
      modalContent: 'modal Content'
    }
  },
  computed: {
    myVideoPlayer() {
      return this.$refs.videoPlayer.player
    }
  },
  async mounted() {
    console.log(this.userServerId)
    if (localStorage.getItem('userID') === null) {
      localStorage.setItem('userID', uuid())
    }
    if (localStorage.getItem('langue') === null) {
      localStorage.setItem('langue', 'fr')
    }
    this.langue = localStorage.getItem('langue')
    if (localStorage.getItem('userServerId') === null) {
      this.userServerId = 0
    } else {
      this.userServerId = localStorage.getItem('userServerId')
    }
    const data = {
      type: 'page',
      detail: 'Mazars',
      version: this.langue,
      SessionId: localStorage.getItem('userID')
    }
    await ReportingService.sendReport(data)
    // console.log(res)
    const imageIsLoaded = (src) => {
      const image = document.createElement('img')
      image.src = src
      return new Promise((resolve, reject) => {
        image.onload = () => {
          resolve(true)
        }
        image.onerror = (error) => {
          reject(error)
        }
      })
    }
    const container = this.$refs.sceneContainer
    const UrlTextureRdcWelcome = '/Mazars/1rdcWelcome.jpg'
    const UrlTextureRdcHall = '/Mazars/2rdcHall.jpg'
    const UrlTextureRdcOpenSpace = '/Mazars/3rdcOpenSpace.jpg'
    const UrlTextureRdcOpenSpaceHall = '/Mazars/4rdcOpenSpaceHall.jpg'
    const UrlTextureRdcOutSide = '/Mazars/5rdcOutSide.jpg'
    const UrlTextureRdcMeetingRoom = '/Mazars/6rdcMeetingRoom.jpg'
    const UrlTexturefirstWelcome = '/Mazars/7firstWelcome.jpg'
    const UrlTexturefirstOffice1 = '/Mazars/8firstOffice1.jpg'
    const UrlTexturefirstOpenSpace1 = '/Mazars/9firstOpenSpace1.jpg'
    const UrlTexturefirstOpenSpace2 = '/Mazars/10firstOpenSpace2.jpg'
    const UrlTexturefirstOffice2 = '/Mazars/11firstOffice2.jpg'
    const UrlTexturesecondWelcome = '/Mazars/12secondWelcome.jpg'
    const UrlTexturesecondOpenSPace = '/Mazars/13secondOpenSPace.jpg'
    const UrlTexturesecondMeetingRoom = '/Mazars/14secondMeetingRoom.jpg'
    const UrlTexturethirdOutSide = '/Mazars/15thirdOutSide.jpg'
    this.ThreeScene = new Scene(
      container,
      UrlTextureRdcWelcome,
      UrlTextureRdcHall,
      UrlTextureRdcOpenSpace,
      UrlTextureRdcOpenSpaceHall,
      UrlTextureRdcOutSide,
      UrlTextureRdcMeetingRoom,
      UrlTexturefirstWelcome,
      UrlTexturefirstOffice1,
      UrlTexturefirstOpenSpace1,
      UrlTexturefirstOpenSpace2,
      UrlTexturefirstOffice2,
      UrlTexturesecondWelcome,
      UrlTexturesecondOpenSPace,
      UrlTexturesecondMeetingRoom,
      UrlTexturethirdOutSide
    )
    this.ThreeScene.init()
    const loaded = []

    await imageIsLoaded(UrlTextureRdcWelcome)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRdcHall)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRdcOpenSpace)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRdcOpenSpaceHall)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRdcOutSide)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRdcMeetingRoom)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturefirstWelcome)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturefirstOffice1)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturefirstOpenSpace1)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturefirstOpenSpace2)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturefirstOffice2)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturesecondWelcome)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturesecondOpenSPace)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturesecondMeetingRoom)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTexturethirdOutSide)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })

    this.isLoaded = !loaded.includes((r) => false)
    this.ThreeScene.EventBus.$on('i-got-hover-in', (obj) => {
      this.popover.show = true
      this.popover.popoverContent = obj.popoverContent
      this.popover.position = obj.mouseCoordinates
    })
    this.ThreeScene.EventBus.$on('i-got-hover-out', () => {
      this.popover.show = false
    })
    this.ThreeScene.EventBus.$on('playVideo', () => {
      this.$refs.videoChroma.muted = false
      this.$refs.videoChroma.play()
      this.$refs.audio.muted = true
    })
    this.ThreeScene.EventBus.$on('stop', () => {
      this.$refs.videoChroma.muted = true
      this.$refs.videoChroma.pause()
      this.$refs.videoChroma.currentTime = 0
    })
    this.ThreeScene.EventBus.$on('clickedToPlay', (o) => {
      this.popover.show = false
      var station = ''
      const modalContent = (index) => {
        switch (index) {
          case 1:
            station = 'Mazars'
            return MODAL_CONENT_1
          case 2:
            station = 'Bienvenue au cabinet Mazars en Tunisie'
            return MODAL_CONENT_2
          case 3:
            station = 'Département Outsourcing'
            return MODAL_CONENT_3
          case 4:
            station = 'Mazars en Tunisie s’engage'
            return MODAL_CONENT_4
          case 5:
            station = 'Il ne s’agit jamais seulement du travail '
            return MODAL_CONENT_5
          case 6:
            station = 'Vous n’aurez jamais une seconde chance de démarrer votre carrière'
            return MODAL_CONENT_6
          case 7:
            station = 'Département Audit, IT et Tax'
            return MODAL_CONENT_7
          case 8:
            station = 'Le département Audit'
            return MODAL_CONENT_8
          case 9:
            station = 'Open space'
            return MODAL_CONENT_9
          case 10:
            station = 'Mazars'
            return MODAL_CONENT_10
          case 11:
            station = 'Département Conseil Financier et RH'
            return MODAL_CONENT_11
        }
        return modalContent
      }
      this.modalH4 = modalContent(o.playIndex).modalH4
      this.modalContent = modalContent(o.playIndex).modalContent
      this.slides = modalContent(o.playIndex).slides
      this.visibleModalPlay = true
      this.sendStats('station', station)
    })
    this.ThreeScene.EventBus.$on('clickedToQuizz', (o) => {
      this.popover.show = false
      this.visibleModalQuizz = true
      document.getElementById('myAudio').muted = false
      console.log('ID', this.userServerId)
      this.quizLimit()
      this.sendStats('quiz', 'i n s c r i p t i o n')
    })
    this.ThreeScene.EventBus.$on('goToScene', (o) => {
      console.log('enter')
      var sceneIndex = localStorage.getItem('sceneIndex')
      var Scene_Name = Scene_Names[sceneIndex].Scene_Name
      console.log('Scene_Name', Scene_Name)
      this.popover.show = false
      this.sendStats('scene', Scene_Name)
    })
    this.ThreeScene.EventBus.$on('clickedToGoPage', (o) => {
      this.$router.push(o.url)
    })
  },
  created() {
    this.fetchData()
    this.quizLimit()
  },
  unmounted() {
    this.ThreeScene.destroy()
    this.ThreeScene = null
    this.player = null
  },
  beforeDestroy() {
    if (this.player) {
      this.player.stop()
      this.inPlay = false
    }
  },
  methods: {
    onSubmit() {
      this.$refs.quizzFormRef.validate((valid) => {
        if (valid) {
          const req = {
            FirstName: this.formQuizz.FirstName,
            LastName: this.formQuizz.LastName,
            HommeFemme: this.formQuizz.gender,
            Region: this.formQuizz.region,
            Age: this.formQuizz.age + ''
          }
          // should removed console
          console.log(req)
          this.sendUserForm(req)
        } else {
          return false
        }
      })
    },
    quizLimit() {
      var QI = localStorage.getItem('questionIndex')
      this.questionIndex = JSON.parse(QI)
      this.quizzCurrent = JSON.parse(QI)
      return this.questionIndex
    },
    showModalx() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    closeQuiz: function () {
      this.visibleModalQuizz = false
      console.log('stop sound')
    },
    closePopup: function () {
      this.isOpen = false
      this.showModal = false
    },
    showPopupp: function () {
      this.isOpen = true
      this.showModal = true
    },
    movetoscene: function (x, et) {
      if (et === '0') {
        this.src3 = src3off
        this.src2 = src2off
        this.src1 = src1off
        this.src0 = src0on
      } else if (et === '1') {
        this.src3 = src3off
        this.src2 = src2off
        this.src1 = src1on
        this.src0 = src0off
      } else if (et === '2') {
        this.src3 = src3off
        this.src2 = src2on
        this.src1 = src1off
        this.src0 = src0off
      } else if (et === '3') {
        this.src3 = src3on
        this.src2 = src2off
        this.src1 = src1off
        this.src0 = src0off
      }
      return (this.ThreeScene.goToScene(x), this.isOpen)
    },
    showPopup() {
      document.getElementById('myAudio').muted = false
    },
    closeModalPlay(e) {
      if (this.player) {
        this.inPlay && this.player.stop()
        this.inPlay = false
        this.playPauseIcon = 'caret-right'
      }
      this.visibleModalPlay = false
    },
    initPlayer(file) {
      const self = this
      this.player = new Howl({
        src: file,
        autoplay: false,
        loop: false,
        volume: 1,
        onend: function () {
          self.inPlay = false
          self.playPauseIcon = 'caret-right'
        }
      })
    },
    async sendStats(typeS, detailS) {
      const data = {
        type: typeS,
        detail: detailS,
        version: localStorage.getItem('version'),
        SessionId: localStorage.getItem('userID')
      }
      await ReportingService.sendReport(data)
      // console.log(res)
    },
    async fetchData() {
      this.loadingQuizz = true
      try {
        const { data } = await QuizzService.getQuizz()
        this.quizzSteps = data.content.map((q) => ({
          questionNumber: q.questionNumber,
          questionText: q.questionText,
          videoFilePath: API_URL + q.videoFilePath,
          myAnswer: false,
          answerId: 0,
          answers: q.answers.map((a) => ({
            id: a.id,
            answerText: a.answerText,
            isCorrect: a.isCorrect,
            questionId: a.questionId
          }))
        }))
        var dataEnquette = await QuizzService.getEnquette()
        this.enquetteSteps = dataEnquette.data.content.map((q) => ({
          questionNumber: q.questionNumber,
          questionText: q.questionText,
          isFreeAnswer: q.isFreeAnswer
        }))
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingQuizz = false
      }
    },
    async sendMyAnswers(dataReq) {
      try {
        await QuizzService.sendMyAnswers(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    },
    async sendAnswer(dataReq) {
      try {
        await QuizzService.sendAnswer(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    },
    async sendEnquetteAnswer(dataReq) {
      try {
        await QuizzService.sendEnquetteAnswer(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    },
    async sendUserForm(dataReq) {
      try {
        await QuizzService.sendUserForm(dataReq)
        this.userServerId = localStorage.getItem('userServerId')
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
      this.userServerId = localStorage.getItem('userServerId')
    },
    nextQuizz(e, answerIndex, quizzIndex) {
      const parentChildNodes = [...e.path[1].childNodes]
      console.log('parentChildNodes', parentChildNodes)
      this.quizzSteps[quizzIndex].myAnswer = this.quizzSteps[quizzIndex].answers[answerIndex].isCorrect
      console.log('is correct', this.quizzSteps[quizzIndex].myAnswer)
      this.quizzSteps[quizzIndex].answerId = this.quizzSteps[quizzIndex].answers[answerIndex].id
      console.log('answerindex', this.quizzSteps[quizzIndex].answerId)
      parentChildNodes.map((b, index) => {
        const isCorrect = this.quizzSteps[quizzIndex].answers[index].isCorrect
        console.log('isCorrect', isCorrect)
        b.classList.add(isCorrect ? 'right-answer' : 'wrong-answer', isCorrect ? 'animate__shakeX' : 'animate__flash')
        b.disabled = false
      })
      setTimeout(() => {
        parentChildNodes.map((b, index) => {
          const isCorrect = this.quizzSteps[quizzIndex].answers[index].isCorrect

          b.classList.remove(
            isCorrect ? 'right-answer' : 'wrong-answer',
            isCorrect ? 'animate__shakeX' : 'animate__flash'
          )
          b.disabled = false
        })
        ++this.quizzCurrent
        console.log('Current Quiz Index : ' + this.quizzCurrent)
        console.log('Current Question Set Index : ' + this.questionIndex)
      }, 1500)
      const req = {
        Id: this.quizzSteps[quizzIndex].answerId,
        QuestionId: this.quizzSteps[quizzIndex].questionNumber,
        IsCorrect: this.quizzSteps[quizzIndex].myAnswer,
        UserId: localStorage.getItem('userServerId')
      }
      // should removed console
      console.log(req)
      this.sendAnswer(req)
      // this.$message.success('Processing complete!')
    },

    nextEnquette(e, reponse, EnqueteIndex) {
      if (reponse === 'Free') {
        reponse = this.freeResponse
      }
      const req = {
        QuestionId: this.enquetteSteps[EnqueteIndex].questionNumber,
        Answer: reponse
      }
      ++this.enquetteCurrent
      this.sendEnquetteAnswer(req)
      // this.$message.success('Processing complete!')
    },
    playChoice(video) {
      if (this.version === 'Visual') {
        this.$refs.videoPlayerInterVideos.player.src(video.srcSL)
      } else {
        this.$refs.videoPlayerInterVideos.player.src(video.src)
      }
      this.$refs.videoPlayerInterVideos.player.play()
      this.visibleModalInterVideosChoice = false
    },
    gotoSecond() {
      this.ThreeScene.goToScene(11)
    }
  }
}
</script>

<style lang="scss" scoped>
.some-modal-content {
  .buttons button {
    margin: 1rem;
    background-color: rgba(63, 63, 63, 0.123);
    :hover::before {
      transform: rotate(360deg);
    }
  }
}
.enqResp {
  padding-top: 85%;
  margin-left: -5%;
  color: #007aff;
  font-weight: 700;
  font-size: 12px;
}
.right-answer {
  color: white !important;
  background-color: rgb(0, 255, 21) !important;
}
.wrong-answer {
  color: white !important;
  background-color: red !important;
}
.statpopup {
  background-color: #fff;
  height: 50%;
}
.logo {
  margin-top: 2%;
  margin-right: 0;
}
.etage {
  position: fixed;
  top: 10%;
  right: 3%;
  width: 100px;
  height: 300px;
  background-image: url('../assets/bg_ascenseur.png');
  background-size: 100% 100%;
}
.eta2 {
  position: absolute;
  height: 45px;
  bottom: 0;
  top: 0em;
  margin-top: 70px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  transition: 0.2s;
  cursor: pointer;
}
.eta2:hover {
  background-image: url('../assets/etage/3_on.png');
}
.eta3 {
  position: absolute;
  bottom: 0;
  height: 45px;
  top: 0;
  margin-top: 130px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  transition: 0.2s;
  cursor: pointer;
}
.eta3:hover {
  background-image: url('../assets/etage/2_off.png');
}
.eta4 {
  position: absolute;
  height: 45px;
  bottom: 0;
  top: 190px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  transition: 0.2s;
  cursor: pointer;
}
.eta4:hover {
  background-image: url('../assets/etage/1_on.png');
}
.eta5 {
  position: absolute;
  height: 45px;
  bottom: 0;
  top: 250px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  transition: 0.2s;
  cursor: pointer;
}
.eta5:hover {
  background-image: url('../assets/etage/0_on.png');
}
.textarea {
  margin-left: 8%;
}
.bouttonEnv {
  background-color: #0071ce;
  color: white;
  width: 20%;
  margin-left: 40%;
}
.buttonl {
  position: inherit;
  background: url('../assets/contacto.png') no-repeat;
  border-color: transparent;
  width: 20%;
  height: 61px;
  margin-left: 19%;
}
.buttonl:hover {
  background-image: url('../assets/contact.png');
}
.buttonc {
  position: fixed;
  top: 0;
  margin-top: 2%;
  background: url('../assets/bt_tuto_off.png') no-repeat;
  border-color: transparent;
  border: 1px;
  border-radius: 0 0 0 10px;
  width: 100px;
  height: 32px;
  background-size: 100% 100%;
}
.buttonc:hover {
  background-image: url('../assets/bt_tuto_on.png');
  border-color: transparent;
}
.buttonq {
  background: url('../assets/questo.png') no-repeat;
  border-color: transparent;
  width: 20%;
  height: 61px;
  margin-left: 61%;
  padding: 0 0 0 0;
}
.buttonq:hover {
  background-image: url('../assets/quest.png');
}
.closestat {
  border: 3px solid white;
  font-weight: bold;
  background-color: transparent;
  color: white;
  position: absolute;
  top: -5%;
  right: -4%;
}
.header {
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.95);
  }
  .router-link-exact-active.is-active {
    color: #ffffff;
    border-bottom: 1px solid;
  }
}
.navigation {
  width: 100%;
  position: fixed;
  bottom: 15px;
}
.lg {
  background-color: #fff;
  top: 5%;
  width: auto;
  height: 100px;
  position: fixed;
  border-radius: 0 10px 10px 0;
}
.answer {
  padding: 10px;
  border: 0.5px solid #0071ce;
  border-radius: 10px;
  color: #0071ce;
}
.answer:hover {
  background-color: #0071ce;
  color: white;
}
.h {
  color: #0071ce;
  font-family: 'Robot';
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
}
.closebutton {
  border: 3px solid white;
  font-weight: bold;
  background-color: transparent;
  color: white;
  position: absolute;
  top: -6%;
  right: -7%;
}
.poquiz {
  margin-top: 1%;
}
.quizContent {
  height: 100%;
  margin-right: 30%;
  width: 900px;
  background: white;
  right: 50%;
  aspect-ratio: auto;
}
.compteur {
  height: 2%;
  padding: 4px 4px 4px 4px;
  width: auto;
  background-color: #0071ce;
  color: white;
  font-size: 15px;
  right: 0;
  border-radius: 5px 0px 0px 5px;
}
.plan-popup {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.area {
  cursor: pointer;
}
.line {
  margin-left: 14%;
  margin-right: 14%;
  margin-bottom: 5%;
  border: 1px solid #0071ce;
}
.tr {
  border-color: transparent;
  background-color: transparent;
  background: url('../assets/satis/tr.png') no-repeat;
  background-size: 100%;
  width: 100px;
  height: 100px;
}
.tr:hover {
  background-image: url('../assets/satis/tr_o.png');
  background-size: 100%;
}

.sat {
  border-color: transparent;
  background-color: transparent;
  background: url('../assets/satis/sat.png') no-repeat;
  width: 100px;
  height: 100px;
  background-size: 100%;
}
.sat:hover {
  background-image: url('../assets/satis/sat_o.png');
}
.neu {
  border-color: transparent;
  background-color: transparent;
  background: url('../assets/satis/neu.png') no-repeat;
  background-size: 100%;
  width: 100px;
  height: 100px;
}
.neu:hover {
  background-image: url('../assets/satis/neu_o.png');
}
.in_o {
  border-color: transparent;
  background-color: transparent;
  background: url('../assets/satis/in_o.png') no-repeat;
  background-size: 100%;
  width: 100px;
  height: 100px;
}
.in_o:hover {
  background-image: url('../assets/satis/in.png');
}
.trin {
  border-color: transparent;
  background-color: transparent;
  background: url('../assets/satis/tresinsatisfait_off.png') no-repeat;
  background-size: 100%;
  width: 100px;
  height: 100px;
}
.trin:hover {
  background-image: url('../assets/satis/tresinsatisfait_on.png');
}
.loading-bar {
  height: 10px;
  bottom: 0px;
  padding-bottom: 0%;
  // border-radius: 0px 0px 15px 15px;
  background: #19329a;
}

@media screen and (max-width: 1243px) {
  .plan-popup {
    width: 100%;
    height: 100%;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 25px;
  }
  .buttonc {
    background-size: 100%;
    margin-left: 40%;
    width: 20%;
    height: 60px;
  }
  .buttonl {
    background-size: 100%;
    margin-left: 19%;
    height: 60px;
    width: 20%;
  }
  .buttonq {
    background-size: 100%;
    margin-left: 61%;
    height: 60px;
    width: 20%;
  }
  // .header {
  //   margin-right: 50%;
  // }
  .et {
    width: 70px;
    height: 250px;
    margin-right: -120px;
  }
  // .etage {
  //   right: 90px;
  //   width: 5%;
  //   height: 5%;
  // }
  .eta2 {
    top: -70%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta3 {
    top: -100%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta4 {
    top: 460%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta5 {
    top: 620%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
}
@media screen and (max-width: 768px) {
  .some-modal-content {
    .buttons button {
      margin: 1rem;
      background-color: rgba(0, 0, 0, 0.35);
      :hover::before {
        transform: rotate(360deg);
      }
    }
  }
  .right-answer {
    color: white !important;
    background-color: rgb(0, 255, 21) !important;
  }
  .wrong-answer {
    color: white !important;
    background-color: red !important;
  }
  .statpopup {
    background-color: #fff;
    height: 50%;
  }
  .logo {
    margin-top: 2%;
    margin-left: 20px;
    margin-bottom: -10%;
    width: auto;
    height: 50px;
  }
  .buttonl {
    position: absolute;
    background: url('../assets/contacto.png') no-repeat;
    border-color: transparent;
    background-size: 100% 100%;
    width: 20%;
    height: 30px;
    margin-left: 19%;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 40px;
  }
  .buttonc {
    position: absolute;
    background: url('../assets/bt_tuto_off.png') no-repeat;
    border-color: transparent;
    background-size: 100% 100%;
    width: 100%;
    height: 30px;
    margin-left: -15%;
    margin-top: -60%;
  }
  .buttonq {
    position: absolute;
    background: url('../assets/questo.png') no-repeat;
    border-color: transparent;
    margin-left: 61%;
    background-size: 100% 100%;
    width: 20%;
    height: 30px;
  }
  .closestat {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -5%;
    right: -3%;
  }
  .header {
    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.95);
    }
    // .navbar {
    //   // height: 30px;
    //   // bottom: 5%;
    //   // width: 100%;
    //   // margin-right: 50%;
    //   background: transparent;
    // }
    .router-link-exact-active.is-active {
      color: #ffffff;
      border-bottom: 1px solid;
    }
  }
  .lg {
    background-color: #fff;
    top: 5%;
    width: auto;
    height: 50px;
    position: fixed;
    border-radius: 0 10px 10px 0;
  }
  .answer {
    padding: 10px;
    margin-left: 50px;
    border: 0.5px solid #0071ce;
    border-radius: 10px;
    width: 75%;
    color: #0071ce;
  }
  .answer:hover {
    background-color: #0071ce;
    color: white;
  }
  .h {
    color: #0071ce;
    font-family: 'Robot';
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
  }
  .closebutton {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -12%;
    right: -4%;
  }
  .poquiz {
    margin-top: 1%;
  }
  .quizContent {
    height: 100%;
    margin-right: 30%;
    width: 900px;
    background: white;
    right: 50%;
    // border-radius: 0px 0px 15px 15px;
    aspect-ratio: auto;
  }
  .compteur {
    height: 2%;
    padding: 2px;
    width: 100px;
    background-color: #0071ce;
    color: white;
    font-size: 15px;
    margin-left: 245px;
    border-radius: 5px 0px 0px 5px;
  }
  .plan-popup {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .area {
    cursor: pointer;
  }
  .line {
    margin-left: 14%;
    margin-right: 14%;
    margin-bottom: 5%;
    border: 1px solid #0071ce;
  }
  .tr {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .tr:hover {
    background: url('../assets/satis/tr_o.png');
    background-size: 100%;
  }
  .sat {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .sat:hover {
    background: url('../assets/satis/sat_o.png');
    background-size: 100%;
  }
  .neu {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .neu:hover {
    background: url('../assets/satis/neu_o.png');
    background-size: 100%;
  }
  .in_o {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .in_o:hover {
    background-image: url('../assets/satis/in.png');
    background-size: 100%;
  }
  .trin {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .trin:hover {
    background-image: url('../assets/satis/tresinsatisfait_on.png');
    background-size: 100%;
  }
  .loading-bar {
    height: 10px;
    bottom: 0px;
    padding-bottom: 0%;
    // border-radius: 0px 0px 15px 15px;
    background: #0071ce;
  }
  .et {
    width: 70px;
    height: 250px;
    margin-right: -120px;
  }
  // .etage {
  //   right: 90px;
  //   width: 5%;
  //   height: 5%;
  // }
  .eta2 {
    top: -15px;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta3 {
    top: 0px;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta4 {
    top: 15px;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta5 {
    top: 30px;
    left: 55px;
    width: 120%;
    height: 120%;
  }
}
@media screen and (max-width: 480px) {
  .some-modal-content {
    .buttons button {
      margin: 1rem;
      background-color: rgba(0, 0, 0, 0.35);
      :hover::before {
        transform: rotate(360deg);
      }
    }
  }
  .right-answer {
    color: white !important;
    background-color: rgb(0, 255, 21) !important;
  }
  .wrong-answer {
    color: white !important;
    background-color: red !important;
  }
  .statpopup {
    background-color: #fff;
    height: 50%;
  }
  .logo {
    margin-top: 2%;
    margin-left: 20px;
    margin-bottom: -10%;
    width: auto;
    height: 50px;
  }
  .buttonl {
    position: absolute;
    background: url('../assets/contacto.png') no-repeat;
    border-color: transparent;
    background-size: 100% 100%;
    width: 30%;
    height: 30px;
    margin-left: 15%;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 40px;
  }
  .buttonc {
    position: absolute;
    background: url('../assets/bt_tuto_off.png') no-repeat;
    border-color: transparent;
    background-size: 100% 100%;
    width: 100%;
    height: 30px;
    margin-left: -15%;
    margin-top: -60%;
  }
  .buttonq {
    position: absolute;
    background: url('../assets/questo.png') no-repeat;
    border-color: transparent;
    margin-left: 55%;
    background-size: 100% 100%;
    width: 30%;
    height: 30px;
  }
  .closestat {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -5%;
    right: -3%;
  }
  .header {
    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.95);
    }
    // .navbar {
    //   // height: 30px;
    //   // bottom: 5%;
    //   // width: 100%;
    //   // margin-right: 50%;
    //   background: transparent;
    // }
    .router-link-exact-active.is-active {
      color: #ffffff;
      border-bottom: 1px solid;
    }
  }
  .lg {
    background-color: #fff;
    top: 5%;
    width: auto;
    height: 50px;
    position: fixed;
    border-radius: 0 10px 10px 0;
  }
  .answer {
    padding: 10px;
    border: 0.5px solid #0071ce;
    border-radius: 10px;
    width: 75%;
    color: #0071ce;
  }
  .answer:hover {
    background-color: #0071ce;
    color: white;
  }
  .h {
    color: #0071ce;
    font-family: 'Robot';
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
  }
  .closebutton {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -12%;
    right: -4%;
  }
  .poquiz {
    margin-top: 1%;
  }
  .quizContent {
    height: 100%;
    margin-right: 30%;
    width: 900px;
    background: white;
    right: 50%;
  }
  .compteur {
    height: 2%;
    padding: 4px;
    width: 120px;
    background-color: #0071ce;
    color: white;
    font-size: 15px;
    // margin-left: 70%;
    border-radius: 5px 0px 0px 5px;
  }
  .plan-popup {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .area {
    cursor: pointer;
  }
  .line {
    margin-left: 14%;
    margin-right: 14%;
    margin-bottom: 5%;
    border: 1px solid #0071ce;
  }
  .tr {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .tr:hover {
    background: url('../assets/satis/tr_o.png');
    background-size: 100%;
  }
  .sat {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .sat:hover {
    background: url('../assets/satis/sat_o.png');
    background-size: 100%;
  }
  .neu {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .neu:hover {
    background: url('../assets/satis/neu_o.png');
    background-size: 100%;
  }
  .in_o {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .in_o:hover {
    background-image: url('../assets/satis/in.png');
    background-size: 100%;
  }
  .trin {
    border-color: transparent;
    background-color: transparent;
    background-size: 100%;
    width: 120px;
    height: 120px;
  }
  .trin:hover {
    background-image: url('../assets/satis/tresinsatisfait_on.png');
    background-size: 100%;
  }
  .loading-bar {
    height: 10px;
    bottom: 0px;
    padding-bottom: 0%;
    // border-radius: 0px 0px 15px 15px;
    background: #0071ce;
  }
  .etage {
    right: 6%;
    width: 100px;
    height: 300px;
  }
}
</style>
